const stagingId = 'UA-143032024-2'
const productionId = 'UA-143032024-1'

export default new Map([
  ['cablelabs.local', stagingId],

  /**
   * Wordpress
   */
  ['www.cablelabs.com', productionId],
  ['staging.cablelabs.com', stagingId],

  /**
   * Registration Wizard
   */
  ['register.cablelabs.com', productionId],
  ['staging-register.cablelabs.com', stagingId],

  /**
   * Specification Search
   */
  ['specification-search.cablelabs.com', productionId],
  ['specification-search-test.cablelabs.com', stagingId],

  /**
   * Misc. CableLabs Production Sites
   */
  ['dashboard.cablelabs.com', productionId],
  ['community.cablelabs.com', productionId],
  ['reset.cablelabs.com', productionId],

])
