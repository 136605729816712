import { getUserKey, getUserType, fetchUserData } from '../user/index'
import { send } from '../ga/index'
import { set as setCookie, get as getCookie} from 'es-cookie'

const cookieMeta__LoggedInSession = {
  expires: 1,
  domain: '.cablelabs.com',
  secure: true,
}

const cookieMeta__User = {
  expires: 365*2,
  domain: '.cablelabs.com',
  secure: true,
}

export default function sendPageView() {
  if ( getCookie('userKey') ) {

    const userKey = getCookie('userKey')
    const userType = getCookie('userType')
    const isLoggedIn = getCookie('isLoggedIn') || 'false'

    console.debug('isLoggedIn: ', isLoggedIn)
    console.debug('getCookie(isLoggedIn): ', getCookie('isLoggedIn'))

    const data = {
      'userId': userKey,          // UserKey (Google's dimension)
      'dimension1': userKey,      // UserKey (user)
      'dimension2': userType,     // UserType (user)
      'dimension3': userType,     // UserType (session)
      'dimension4': isLoggedIn,   // isLoggedIn (session)
    }

    console.debug('pageview: Got cookies', data)
    send('pageview', data)

    // Reset Cookie Expiration
    setCookie('userKey', userKey, cookieMeta__User)
    setCookie('userType', userType, cookieMeta__User)

  } else if (getUserKey()) {
    // We are on InfoZone, or another property with userKey in the page's markup...
    fetchUserData(userData => {
      const userKey = getUserKey()
      const userType = getUserType(userData.groups)

      const data = {
        'userId': userKey,          // UserKey (Google's dimension)
        'dimension1': userKey,      // UserKey (user)
        'dimension2': userType,     // UserType (user)
        'dimension3': userType,     // UserType (session)
        'dimension4': 'true',       // isLoggedIn (session)
      }

      console.debug('pageview: No cookies, but just logged in', data)
      send('pageview', data)

      setCookie('userKey', userKey, cookieMeta__User)
      setCookie('userType', userType, cookieMeta__User)
      setCookie('isLoggedIn', 'true', cookieMeta__LoggedInSession)

    })
  } else {
    console.debug('pageview: Not logged in')
    send('pageview')
  }

}
