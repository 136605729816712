export default function getUserType(userGroups) {
  let userType

  for (let i = 0; i < userGroups.items.length; i++) {
    if (userGroups.items[i].name=='cl-employees') userType = 'employee'
    else if (userGroups.items[i].name=='cl-contractors') userType = 'contractor'
    else if (userGroups.items[i].name=='cl-interns') userType = 'intern'
    else if (userGroups.items[i].name=='cl-members') userType = 'member'
    else if (userGroups.items[i].name=='cl-vendors') userType = 'vendor'
  }

  return userType
}
