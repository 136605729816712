import send from './gaSend'

export default class trackElement {
  constructor(el) {
    this.el = el
    this.dataset = el.dataset
    this.events()
  }

  events() {
    this.el.addEventListener(this.dataset.eventTrigger, () => this._onClick())
  }

  _onClick() {
    send(this._getEventData())
  }

  _getEventData() {
    return {
      hitType: 'event',
      eventCategory: this.dataset.eventCategory,
      eventAction: this.dataset.eventAction,
      eventLabel: this.dataset.eventLabel,
    }
  }

}
