/* global ga:false */
import CONSTANTS from '../../constants/index'
export default function send(args, additionalData) {
  _send(args, additionalData)
}

function _send(...args) {
  const data = {
    hitType: args[0].hitType,
    eventCategory: args[0].eventCategory,
    eventAction: args[0].eventAction,
    eventLabel: args[0].eventLabel,
  }

  if (typeof ga !== 'undefined') {
    const prefixedSend = `${CONSTANTS.gaTrackerName ? `${CONSTANTS.gaTrackerName}.` : ''}send`
    console.debug('Recording event', prefixedSend, data)
    ga(prefixedSend, args[0], args[1])
  } else if (typeof ga === 'undefined') {
    console.error('Not recording event as Google Analytics is not loaded', data)
  } else {
    console.error('Not recording event for UNKNOWN REASON.', data)
  }
}
