import getUserName from './getUserName'

export default function fetchUserData(callback) {

  const userName = getUserName()
  const xhr = new XMLHttpRequest()

  if (!xhr) {
    alert('Giving up :( Cannot create an XMLHTTP instance')
    return false
  }

  xhr.onreadystatechange = alertContents
  xhr.open('GET', `https://community.cablelabs.com/rest/api/2/user?username=${userName}&expand=groups`)
  xhr.send()

  function alertContents() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        callback(JSON.parse(xhr.responseText))
      } else {
        console.error('[fetchUserData]: There was a problem with the request.')
        callback(null, xhr.status)
      }
    }
  }
}
