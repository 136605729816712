/**
 * Searches the DOM for the 'ajs-remote-user-key' meta tag and returns its content.
 * Returns undefined if none is found.
 * @return {String} The userKey.
 */

export default function getUserKey() {
  const metaTag = document.head.querySelector('[name~=ajs-remote-user-key][content]')
  if (metaTag) return metaTag.content
}
