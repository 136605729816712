// Add NodeList.forEach() support to IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

import * as ga from './core/index'

console.debug('ga.getTrackingCode()', ga.getTrackingCode())

ga.loadGaScript(ga.getTrackingCode())
ga.sendPageView()

if (document.readyState !== 'loading') {
  attachListeners()
} else {
  document.addEventListener('DOMContentLoaded', attachListeners)
}

function attachListeners() {
  document.querySelectorAll('[data-event-trigger]')
    .forEach(el => new ga.trackElement(el))
  document.removeEventListener('DOMContentLoaded', attachListeners)
}
