if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    enumerable: false,
    value: function(obj) {
      const newArr = this.filter(el => {
        return el == obj
      })
      return newArr.length > 0
    },
  })
}

export default [
  'cablelabs.com',
  'www.cablelabs.com',
  'community.cablelabs.com',
  'specification-search.cablelabs.com',
].includes(location.host)
