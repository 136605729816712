/* global ga:false */
import CONSTANTS from '../../constants/index'
/**
 * Simply runs the GA async load script.
 * Logs the clientId once the track has been created.
 * @return {void}
 */

export default function loadGAScript(trackingId) {
  _loadScript()
  _createTracker(trackingId)
}

function _loadScript() {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date()
    a = s.createElement(o),
    m = s.getElementsByTagName(o)[0]
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')
}

function _createTracker(trackingId, name = CONSTANTS.gaTrackerName) {
  ga('create', {
    name,
    trackingId,
    cookieDomain: 'auto',
  })
}
